<template>
        <section id="win-login">
            <div class="h-mask">
                <div class="h-login-p">
                    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                        <el-form-item label="用户名" prop="username">
                            <el-input v-model="ruleForm.username"></el-input>
                          </el-form-item>
                        <el-form-item label="密码" prop="pass">
                          <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item>
                          <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
                        </el-form-item>
                      </el-form>
                </div>
            </div>
        </section>
</template>
<script>
 import {mapState,mapGetters} from 'vuex'
export default {
    data(){
        return {
            ruleForm:{
                username:'',
                pass:''
            },
            rules:{
                username:[{required: true, message: '请输入用户名', trigger: 'blur'}],
                pass:[ { required: true, message: '请输入密码',trigger: 'blur' }]
            }
        }
     },
    computed:{
    ...mapState({}),
    ...mapGetters({})
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
            if(valid && this.ruleForm.pass === 'hdf_menhu'){
                // 验证通过后 设置userName
                const userName = this.ruleForm.username;
                this.$store.commit("setUserName",userName);
                sessionStorage.setItem('userName', userName);
                this.$router.push({name: "mycenter",query:{t: new Date().getTime()}})
            } else {
                alert("密码错误")
                console.log('error submit!!');
                return false;
            }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        }
    }
}
</script>
<style scoped>
#win-login{
    padding: 100px 0;
}
.h-mask{position: fixed;top: 0;bottom:0;left:0;right:0;background-color: #2d3a4b;z-index: 999;}
.h-login-p{position:fixed;width: 400px;z-index: 1000;top: 50%;margin-top: -80px;left: 50%;margin-left: -200px;}

</style>
